import { urlFcMenuList, urlMenuList } from "../functional/urls"
import { useRecoilState } from "recoil"
import { userLoginState } from "../../status/UserState"
import { useEffect } from "react"
import { loginHandler, logoutHandler } from "../../utility/LoginHandler"
import { MyLink } from "../elements/Link"
import { globalHistory } from '@reach/router'
import EnterIcon from "../elements/icons/EnterIcon"
import clsx from "clsx"
import Logos from "../parts/Logos"
import SwitchLanguageButton from "../elements/buttons/SwitchLanguageButton"

interface Props {
}

export default function Header({}:Props){
	const { pathname } = globalHistory.location
	const [userLogin, setUserLogin] = useRecoilState(userLoginState)
	useEffect(()=>{
		// setUserLogin(true)
	},[])
	console.log(userLogin)
	const isMember = pathname.match(/member/g)
	let menuList = isMember ? urlFcMenuList : urlMenuList
	const commonClass = "z-50 fixed"
	const blendModeClass = "mix-blend-difference"
	return (
		<header id="header" className="text-[#cccccc]">
			<Logos isBlendMode/>
			<div className={clsx(
				"flex items-center justify-end h-auto w-[calc(100%_-_240px)]",
				"top-[30px] right-11 z-40 fixed",
				blendModeClass,
			)}>
				<nav className={clsx(
					"font-en font-bold text-base hidden",
					isMember ? "fcnav:block" : "nav:block"
				)}>
					<ul className="flex flex-wrap justify-end items-center gap-x-5 gap-2-y">
						{ menuList.map(({label,to, blank},i)=>{
							let isClick = to === "login" || to === "logout"
							return (
								<li key={i}>
									{ !isClick && <MyLink to={to} blank={blank}>{label}</MyLink> }
									{ to === "login" &&
										<button onClick={()=> loginHandler(true)} className="flex items-center">
											<span className="mr-2 -mt-[0.1em] [&_path]:fill-[#ccc]">
												<EnterIcon width={24} height={24}/>
											</span>
											{label}
										</button>
									}
									{ to === "logout" &&
										<button onClick={()=>logoutHandler({cb:()=>{}})} className="">
											{label}
										</button>
									}
								</li>
							)
						})}
					</ul>
				</nav>
				<div className={clsx(
					`translate absolute right-5 top-[2px] -translate-y-1/2`,
					isMember ?
					"fcnav:relative fcnav:top-auto fcnav:right-auto fcnav:translate-y-0  fcnav:ml-6"
						:
					"nav:relative nav:top-auto nav:right-auto nav:translate-y-0  nav:ml-6",
				)}>
					<SwitchLanguageButton/>
				</div>
			</div>
		</header>
	)
}