module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"html":{"useGatsbyImage":true,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":true,"placeholderType":"none","gatsbyImageOptions":{"loading":"lazy","placeholder":"none"},"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70},"develop":{"hardCacheData":false,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"url":"https://wp.ssj.net/__graphql","type":{"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"hostingWPCOM":false,"useACF":true,"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"志尊淳オフィシャルサイト - JUN SHISON OFFICIAL SITE","short_name":"S.S.J","start_url":"/","background_color":"#000000","theme_color":"#000000","icon":"static/icon_fc.png","include_favicon":false,"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"72e97c4ca300698bf238bfda41e876ef"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PWNR9WKL","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
